import { async } from "@firebase/util";
import React ,{useState,useEffect}from "react";
import '../../App.css';
import TextField from "@material-ui/core/TextField";
import { Text } from '@react-ui-org/react-ui';
import LazyLoad from 'react-lazyload';
import { Alert, Button, TextInput } from 'evergreen-ui'
import axios from "axios";
import "@fontsource/dm-serif-text";
//import axios from '../../axios'
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
  } from "react-router-dom";
  import { db } from "./firebase";

  import playstore from '../pages/Play Store button.png'
  import appstore from '../pages/App Store button.png'
  import jiffybooklogo from '../pages/Jiffybook_logo.png'
import { getFirestore, collection, getDocs,firestore,updateDoc,doc,getDoc} from 'firebase/firestore';
const Blog = () => {
    const [businessname, setbusinessname] = useState("");
  const [businessweb, setbusinessweb] = useState("");
  const [textmessage, settextmessage] = useState([]);
  const [businesslogo, setbusinesslogo] = useState("");
  const [businessprofile, setbusinessprofile] = useState("");
  const [businesssignature, setbusinesssignature] = useState("");
  const [countrycode,setcountrycode]=useState('')
  const [translationview,settranslationview]=useState(false)
  const [translationmessage,settranslationmessage]=useState("")
  const[imageload,setimageload]=useState(false)
  const [name, setName] = useState('')
  const [phone,setPhone]=useState('')
  const [headertext,setHeadertext]=useState('')
  const [buttontext,setButtontext]=useState('')
  const [errormessage,setErrormessage]=useState('')
  const [thanksview,setthanksview]=useState(false)
  const { id } = useParams();
  const listitems= ["List Item 1", "List Item 2", "List Item 3"]
 
  const showLoader = () => {
    return (
      <div className="loader">
        
      </div>
    );
  };
 
  useEffect(() => {
   var tempimage='';
   
      async function fetchdata(){
          
        
     console.log("db",db)
     const docRef = doc(db, 'Invitation',id);
     const docSnap = await getDoc(docRef);
        console.log(docSnap.data().strBlogo)
//    const enmessage=await getDoc(doc(db,'Message','en'))
  //  const eemessage=await getDoc(doc(db,'Message','ee'))
    //const frmessage=await getDoc(doc(db,'Message','fr'))
    //console.log("english",enmessage.data().strBody)
        tempimage=docSnap.data().strBlogo
       setbusinesslogo(tempimage)
       console.log("logoimage",businesslogo)
       tempimage=docSnap.data().strBprofile
       setbusinessprofile(tempimage)
       console.log("profileimage",businessprofile)
       tempimage=docSnap.data().strBname
       setbusinessname(tempimage)
       tempimage=docSnap.data().strBweb
       setbusinessweb(tempimage)
       tempimage=docSnap.data().strCountrycode
       setcountrycode(tempimage)
       tempimage=docSnap.data().Headertext
       setHeadertext(tempimage)
       tempimage=docSnap.data().Buttontext
       setButtontext(tempimage)

      // tempimage=docSnap.data().strBody_en
       //settextmessage(tempimage)
    var   tempsign=docSnap.data().strBsignature
       setbusinesssignature(tempsign)
       

    }
    fetchdata()
   
  }, []);
  
//const agentarray=tempseg;
//tempseg.map(label=> {
  //  setCustomersData([label]);
    // })
//console.log("agent",tempseg)

let newText  = 'add \n substract'
console.log("newtext",typeof(newText))
console.log(imageload)

function onemailchange(e){
  setErrormessage('')
setName(e.target.value)}
function onemailend (e) {
  setErrormessage('')
  setName(e.target.value)
  //validateEmail(e.target.value)
  
  if(name!=''){
      if(!validateEmail(e.target.value)){
        setErrormessage('Invalid')
      }
      else{
        setName(e.target.value)
      }
     }
    }

function  validateEmail (email){
  setErrormessage('')
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);

  }
function submit() {
    console.log(name,phone)
    //alert('Hello!');
   if(name=='' && phone==''){
  setErrormessage('Email or Phone number required')
   } 
   else{
    axios({
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        url:'https://us-central1-jiffybook-app.cloudfunctions.net/Consumercommunityreg',
    
        data:{
            CMail:name,
            CPhone:countrycode+phone,
            Bid:id
        },
      
      }).then(setthanksview(true))
    }
  }
  return (
  
    <div>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        
        <div className="header" >
            <a href='https://www.jiffybook.indreamer.com' style={{color:'black',
            textDecoration:'none'}}
            >
            <img className="headerimage" src={jiffybooklogo}/>
            <div className="headertext">JiffyBook</div>
            </a>
            
        </div>
        {thanksview==false?(
     <div className="joincommunitycontent">
       {imageload==true?(
     <div className="load">
       {showLoader()}
    </div>):null}
  
       
     
     
      
    <div className='businessname' style={{marginTop:'25%'}}><br></br>{businessname}</div>
    <div className='businessname' ><br></br>{headertext}</div>
     
      
      <div className='inputs'>
        <div style={{flexDirection:'row'}}>
      <TextInput
        onChange={onemailchange}
        onBlur={onemailend}
        placeholder="Email"
      /> <br></br>
     
    <br></br>
    <div style={{flexDirection:'column'}}>
    <TextInput
        onChange={(e) => setcountrycode(e.target.value)}
        placeholder={countrycode}
        style={{width:45}}
      /> 
      <TextInput
        onChange={(e) => setPhone(e.target.value)}
        placeholder="Phonenumber"
        style={{width:'83%',marginLeft:2}}
      /> 
    </div>
      </div>
      
    </div>
    
    <div >
        <Button className="joinbutton" style={{backgroundColor:'#e75600',color:'white',borderRadius:20,marginTop:17,
                        fontSize:16}}
         onClick={submit}>{buttontext}</Button>
      
    </div>
    {errormessage!=''?(
    <Alert
    intent="danger"
    title={errormessage}
    marginTop={10}
    marginLeft={'15%'}
    width={'65%'}
  />):null}
      <div className="joincommunitystorelinks">
          <a href="https://play.google.com/store/apps/details?id=com.indreamer.jiffybook">
             <img className="playstore" src={playstore} alt="playstore link"
              />
             </a>
             <a href="https://apps.apple.com/app/id1514749994">
             <img className="appstore" src={appstore} alt="appstore link" />
             </a>
        </div>
        
        </div>
        ):null}
        {thanksview==true?(
            <div className="joincommunitycontent">
                <div className="businessname" style={{marginTop:'50%'}}>
                    Thank you for joining JiffyBook Community !!</div>
                <div className="joincommunitystorelinks">
          <a href="https://play.google.com/store/apps/details?id=com.indreamer.jiffybook">
             <img className="playstore" src={playstore} alt="playstore link"
              />
             </a>
             <a href="https://apps.apple.com/app/id1514749994">
             <img className="appstore" src={appstore} alt="appstore link" />
             </a>
        </div>
            </div>
        ):null}
    </div>
    
  );
};
  
export default Blog;