import { async } from "@firebase/util";
import React ,{useState,useEffect}from "react";
import '../../App.css';
import TextField from "@material-ui/core/TextField";
import { Text } from '@react-ui-org/react-ui';
import LazyLoad from 'react-lazyload';

import { Alert, Button, TextInput } from 'evergreen-ui'
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
  } from "react-router-dom";
  import { db } from "./firebase";
  import weburl from '../pages/kok.png'
  import userimg from '../pages/users.png'
  import blogo from '../pages/blogo.png'
import axios from "axios";
import { getFirestore, collection, getDocs,firestore,updateDoc,doc,getDoc} from 'firebase/firestore';
const Blog = () => {
    const [businessname, setbusinessname] = useState("");
  const [businessweb, setbusinessweb] = useState("");
  const [textmessage, settextmessage] = useState([]);
  const [businesslogo, setbusinesslogo] = useState("");
  const [businessprofile, setbusinessprofile] = useState("");
  const [businesssignature, setbusinesssignature] = useState("");
  const [translationview,settranslationview]=useState(false)
  const [translationmessage,settranslationmessage]=useState("")
  const[imageload,setimageload]=useState(true)
  const { id } = useParams();
  const [name, setName] = useState('')
  const [phone,setPhone]=useState('')
  const [headertext,setHeadertext]=useState('')
  const [buttontext,setButtontext]=useState('')
  const [errormessage,setErrormessage]=useState('')
  const [thanksview,setthanksview]=useState(false)
  const [countrycode,setcountrycode]=useState('')
  const showLoader = () => {
    return (
      <div className="loader">
        
      </div>
    );
  };
 
function onemailchange(e){
  setErrormessage('')
setName(e.target.value)}
function onemailend (e) {
  setErrormessage('')
  setName(e.target.value)
  //validateEmail(e.target.value)
  
  if(name!=''){
      if(!validateEmail(e.target.value)){
        setErrormessage('Invalid')
      }
      else{
        setName(e.target.value)
      }
     }
    }

function  validateEmail (email){
  setErrormessage('')
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);

  }
function submit() {
    console.log(name,phone)
    //alert('Hello!');
   if(name==''){
  setErrormessage('Email required')
   } 
   else{
    var posturl='';
    if(countrycode=='91'){
      posturl='https://us-central1-jiffybook-india.cloudfunctions.net/Consumercommunityreg'
    }
    else if(countrycode=='372'){
      posturl='https://us-central1-jiffybook-app.cloudfunctions.net/Consumercommunityreg'
       
    }
    setButtontext("Joining..")
    axios({
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        //url:'https://us-central1-jiffybook-preprod.cloudfunctions.net/Consumercommunityreg',
        url:posturl,
        //url:'https://us-central1-jiffybook-india-preprod.cloudfunctions.net/Consumercommunityreg',
        data:{
            CMail:name,
            CPhone:countrycode+phone,
            Bid:id,
            strBprofileimage:businessprofile,
            strBname:businessname
        },
      
      }).then(
        setTimeout(function(){
          window.location.href='https://gallery.jiffybook.eu/Thanks/'+id
          },4000)
      )
    }
  }
  
  useEffect(() => {
   var tempimage='';
   
      async function fetchdata(){
          
        
     console.log("db",db)
     const docRef = doc(db, 'Invitation',id);
     const docSnap = await getDoc(docRef);
        console.log(docSnap.data().strBlogo)
    //const enmessage=await getDoc(doc(db,'Message','en'))
    //const eemessage=await getDoc(doc(db,'Message','ee'))
    //const frmessage=await getDoc(doc(db,'Message','fr'))
    //console.log("english",enmessage.data().strBody)
        tempimage=docSnap.data().strBlogo
       setbusinesslogo(tempimage)
       console.log("logoimage",businesslogo)
       tempimage=docSnap.data().strBprofile
       setbusinessprofile(tempimage)
       console.log("profileimage",businessprofile)
       tempimage=docSnap.data().strBname
       setbusinessname(tempimage)
       tempimage=docSnap.data().strBweb
       setbusinessweb(tempimage)
       tempimage=docSnap.data().strCountrycode
       setcountrycode(tempimage)
       tempimage=docSnap.data().Headertext
       setHeadertext(tempimage)
       tempimage=docSnap.data().Buttontext
       setButtontext(tempimage)
       
    var   tempsign=docSnap.data().strBsignature
       setbusinesssignature(tempsign)
       if(docSnap.data().strLanguage=='en'){
        tempimage=docSnap.data().strBody_en
       settextmessage(tempimage)
       // settextmessage(enmessage.data().strBody_en)
        //settranslationview(true)
       }
       if(docSnap.data().strLanguage=='ee'){
        tempimage=docSnap.data().strBody_ee
       settextmessage(tempimage)
        //settextmessage(eemessage.data().strBody_ee)
       } 
       if(docSnap.data().strLanguage=='fr'){
        tempimage=docSnap.data().strBody_fr
       settextmessage(tempimage)
        //settextmessage(frmessage.data().strBody_fr)
       }

    }
    fetchdata()
   
  }, []);
  
//const agentarray=tempseg;
//tempseg.map(label=> {
  //  setCustomersData([label]);
    // })
//console.log("agent",tempseg)

let newText  = 'add \n substract'
console.log("newtext",typeof(newText))
console.log(imageload)
const translation = async() => {
 // const enmessage=await getDoc(doc(db,'Message','en'))
//settranslationmessage(enmessage.data().strBody)
//settranslationview(true)
};
const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};
setTimeout(function(){
  document.getElementById('weburl').style.visibility = "visible";
  },4000);
  
setTimeout(function(){
  document.getElementById('weburl').style.visibility = "visible";
  },4000);
  
  setTimeout(function(){
    document.getElementById('logo').style.visibility = "visible";
    },4000);
  return (
  
    <div className="maindiv">
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
       <div >
      {thanksview==false?(
     <div className="content">
      
    {businessprofile?(
      <div className="wrapper">
          <LazyLoad height={100}
          offset={100}>
             <img className="wrapper" onLoad={()=>setimageload(false)}
             src={businessprofile} 
             alt="business profile image" />
             </LazyLoad>
        </div>):null}
        <div>
            <LazyLoad height={100}
            offset={100}>
             <img className="profilewrap"
             id="logo" style={{visibility:'hidden'}}
              src={businesslogo?businesslogo:blogo}
              alt="logo image" />
             </LazyLoad>
        </div>
        {imageload==true?(
     <div className="lds-ripple">
       {showLoader()}
    </div>):null}
        <div  style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
      
        <div className='businessname'  style={{whiteSpace:'nowrap', display:'inline',}}>
       {businessname}
       </div> 
       {businessweb!=''?(
       <div  style={{whiteSpace:'nowrap', display:'inline',}}>
       <a  href={businessweb} style={{color:'black',textDecoration:'none',
    }}
    >
       <img id="weburl" style={{visibility:'hidden',borderRadius:20,}} src={weburl} className='weburl' ></img>
      </a>
      </div>
      ):null}
      </div>
      
      
      <div className='inputs'>
        <div style={{flexDirection:'row'}}>
      <TextInput
        onChange={onemailchange}
        onBlur={onemailend}
        placeholder="Email"
      /> 
      </div>
      
    </div>
    
    <div >
        <Button className="joinbutton" style={{backgroundColor:'#e75600',color:'white',borderRadius:20,
        marginTop:15,fontSize:16,height:24,fontWeight:'bold'}}
         onClick={submit}>{buttontext}
         <img id="weburl" style={{borderRadius:20}} src={userimg} className='userimage' ></img>
     
         </Button>
      
    </div>
    {errormessage!=''?(
    <Alert
    intent="danger"
    title={errormessage}
    marginTop={10}
    marginLeft={'15%'}
    width={'65%'}
  />):null}
      <div className="textmessage" style={{marginTop:10,marginLeft:10,marginRight:10,
    }} dangerouslySetInnerHTML={{ __html:textmessage }}>
         
      </div>
      
     {translationview==true?( <div className="textmessage" style={{marginTop:10,marginLeft:10,marginRight:10,
    }} dangerouslySetInnerHTML={{ __html:translationmessage }}>
         
      </div>):null}
    
      
      
     
        
        </div>):null}
        {thanksview==true?(
            <div className="joincommunitycontent">
                <div className="businessname" style={{marginTop:'50%'}}>
                Thank you for being our guest. We are excited to show you our catalogue in the JiffyBook app!!</div>
            
            </div>
        ):null}
     
    </div>
    </div>
  );
};
  
export default Blog;