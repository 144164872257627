import { async } from "@firebase/util";
import React ,{useState,useEffect}from "react";
import '../../App.css';
const Blog = () => {
    
  const showLoader = () => {
    return (
      <div className="loader">
        
      </div>
    );
  };
setTimeout(function(){
  document.getElementById('weburl').style.visibility = "visible";
  },4000);
  
setTimeout(function(){
  document.getElementById('weburl').style.visibility = "visible";
  },4000);
  return (
  
    <div className="maindiv">
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
       <div >
          <div>
            <div className="joincommunitycontent">
                <div className="businessname" style={{marginTop:'50%'}}>
                Thank you for being our guest. We are excited to show you our catalogue in the JiffyBook app !!</div>
                    <div className="joincommunitystorelinks" >
                    <h5 className="playstore">
               <a href='https://play.google.com/store/apps/details?id=com.indreamer.jiffybook'>
                 <img alt='Get it on Google Play' width="230" 
                 src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                  </h5> 
                   <h5 > 
                   <a href="https://apps.apple.com/app/id1514749994">
                     <img alt='Download on the App Store' width="200" 
                     src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1590624000&h=e52b12f5cbe9c3e97e6154cac1cf80b9"/>
                     </a>
             </h5> 
             </div>              
            </div>
            </div>
       
     
    </div>
    </div>
  );
};
  
export default Blog;