import { async } from "@firebase/util";
import React ,{useState,useEffect}from "react";
import '../../App.css';
import LazyLoad from 'react-lazyload';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
  } from "react-router-dom";
  import { db } from "./firebase";
  import weburl from '../pages/kok.png'
  
import axios from "axios";
import { getFirestore, collection, getDocs,firestore,updateDoc,doc,getDoc} from 'firebase/firestore';
const Blog = () => {
    const [businessname, setbusinessname] = useState("");
  const [businessweb, setbusinessweb] = useState("");
  const [textmessage, settextmessage] = useState([]);
  const [businesslogo, setbusinesslogo] = useState("");
  const [businessprofile, setbusinessprofile] = useState("");
  const [businesssignature, setbusinesssignature] = useState("");
  const [translationview,settranslationview]=useState(false)
  const [translationmessage,settranslationmessage]=useState("")
  const[imageload,setimageload]=useState(true)
  const { id } = useParams();
  const [name, setName] = useState('')
  const [phone,setPhone]=useState('')
  const [headertext,setHeadertext]=useState('')
  const [buttontext,setButtontext]=useState('')
  const [errormessage,setErrormessage]=useState('')
  const [thanksview,setthanksview]=useState(false)
  const [countrycode,setcountrycode]=useState('')
  const showLoader = () => {
    return (
      <div className="loader">
        
      </div>
    );
  };
 
  useEffect(() => {
   var tempimage='';
   
      async function fetchdata(){
          
        
     console.log("db",db)
     const docRef = doc(db, 'Invitation',id);
     const docSnap = await getDoc(docRef);
        console.log(docSnap.data().strBlogo)
    //const enmessage=await getDoc(doc(db,'Message','en'))
    //const eemessage=await getDoc(doc(db,'Message','ee'))
    //const frmessage=await getDoc(doc(db,'Message','fr'))
    //console.log("english",enmessage.data().strBody)
        tempimage=docSnap.data().strBlogo
       setbusinesslogo(tempimage)
       console.log("logoimage",businesslogo)
       tempimage=docSnap.data().strBprofile
       setbusinessprofile(tempimage)
       console.log("profileimage",businessprofile)
       tempimage=docSnap.data().strBname
       setbusinessname(tempimage)
       tempimage=docSnap.data().strBweb
       setbusinessweb(tempimage)
       tempimage=docSnap.data().strCountrycode
       setcountrycode(tempimage)
       tempimage=docSnap.data().Headertext
       setHeadertext(tempimage)
       tempimage=docSnap.data().Buttontext
       setButtontext(tempimage)
    var   tempsign=docSnap.data().strBsignature
       setbusinesssignature(tempsign)
       if(docSnap.data().strLanguage=='en'){
        tempimage=docSnap.data().strBody_en
       settextmessage(tempimage)
       }
       if(docSnap.data().strLanguage=='ee'){
        tempimage=docSnap.data().strBody_ee
       settextmessage(tempimage)
       } 
       if(docSnap.data().strLanguage=='fr'){
        tempimage=docSnap.data().strBody_fr
       settextmessage(tempimage)
        //settextmessage(frmessage.data().strBody_fr)
       }

    }
    fetchdata()
   
  }, []);
  
setTimeout(function(){
  document.getElementById('weburl').style.visibility = "visible";
  },4000);
  
setTimeout(function(){
  document.getElementById('weburl').style.visibility = "visible";
  },4000);
  return (
  
    <div className="maindiv">
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
       <div >
      {thanksview==false?(
     <div className="content">
       {imageload==true?(
     <div className="lds-ripple">
       {showLoader()}
    </div>):null}
    {businessprofile?(
      <div className="wrapper">
          <LazyLoad height={100}
          offset={100}>
             <img className="wrapper" onLoad={()=>setimageload(false)}
             src={businessprofile} 
             alt="business profile image" />
             </LazyLoad>
        </div>):null}
        {businesslogo?(
        <div>
            <LazyLoad height={100}
            offset={100}>
             <img className="profilewrap" src={businesslogo}
              alt="logo image" />
             </LazyLoad>
        </div>):null}
        <div  style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
      
      <div className='businessname'  style={{whiteSpace:'nowrap', display:'inline',}}>
     {businessname}
     </div> 
     {businessweb!=''?(
     <div  style={{whiteSpace:'nowrap', display:'inline',}}>
     <a  href={businessweb} style={{color:'black',textDecoration:'none',
  }}
  >
     <img id="weburl" style={{visibility:'hidden',borderRadius:20,}} src={weburl} className='weburl' ></img>
    </a>
    </div>
    ):null}
    </div>
      
      <div className="textmessage" style={{marginTop:10,marginLeft:10,marginRight:10,
    }} dangerouslySetInnerHTML={{ __html:textmessage }}>
         
      </div>
      
     {translationview==true?( <div className="textmessage" style={{marginTop:10,marginLeft:10,marginRight:10,
    }} dangerouslySetInnerHTML={{ __html:translationmessage }}>
         
      </div>):null}
    
      
      
     
        
        </div>):null}
       
     
    </div>
    </div>
  );
};
  
export default Blog;