
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import Invitation from './components/pages/invitation';
import Joincommunity from './components/pages/joincommunity';
import Gallery from './components/pages/gallery';
import Thanks from './components/pages/thanks'
import { AnimatePresence ,motion} from "framer-motion";
function App() {
return (
    <AnimatePresence>
        <motion.path
         d="M0 100V0l50 50 50-50v100L75 75l-25 25-25-25z"
         //variants={icon}
         initial="hidden"
         animate="visible">
    <Router>
  
    <Routes>
        <Route path='/Invitation/:id' element={<Invitation/>}/>
        <Route path='/Joincommunity/en/:id' element={<Joincommunity/>}/>
        <Route path='/Gallery/:id' element={<Gallery/>}/>
        <Route path='/Thanks/:id' element={<Thanks/>}/>
        
    </Routes>
    </Router>
    </motion.path>
    </AnimatePresence>
);
}
  
export default App;