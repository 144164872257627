//import firebaseConfig from '../config';
import firebase from 'firebase/app';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getFirestore, collection, getDocs,firestore} from 'firebase/firestore';
const firebaseConfig = { 
    apiKey: "AIzaSyBYL1JBeROhtN915DRkPwWtED_2WDzJuLE",
  authDomain: "jiffybook-public.firebaseapp.com",
  projectId: "jiffybook-public",
  storageBucket: "jiffybook-public.appspot.com",
  messagingSenderId: "973914462731",
  appId: "1:973914462731:web:0bac17fbd2b88b4e1f050e"
};
const app=initializeApp(firebaseConfig);
const    db=getFirestore(app)
//const app=initializeApp(firebaseConfig);

const auth = getAuth(app);
//firebase.initializeApp(firebaseConfig); 
const storage=getStorage(app)
export {auth}
export {db,storage}